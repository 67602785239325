import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

gsap.config({
  nullTargetWarn: false
})

export const initGsap = (pageName) => {


  function applyGsapAnimation(elements) {
    elements.forEach((item) => {
      gsap.from(item, {
        scrollTrigger: {
          start: 'top bottom',
          end: 'bottom top',
          trigger: item,
          toggleClass: 'RS-animation--enter',
          once: true,
          scrub: 1,
          stagger: 5,
        }
      });
    });
  }

  const rsRevealAnimations = gsap.utils.toArray('.RS-animation');
  applyGsapAnimation(rsRevealAnimations);

  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      mutation.addedNodes.forEach((node) => {
        if (node.nodeType === 1 && node.classList.contains('RS-animation')) {
          applyGsapAnimation([node]);
        }
      });
    });
  });

  observer.observe(document.body, {
    childList: true,
    subtree: true
  });


  const rsLoopedAnimations = gsap.utils.toArray('.RS-looped-animation');

  rsLoopedAnimations.forEach(item => {
    gsap.from(item, {
      scrollTrigger: {
        start: 'top bottom',
        end: 'bottom top',
        trigger: item,
        toggleClass: 'RS-looped-animation--running',
        scrub: 1
      }
    });
  });



  if(pageName === 'Home'){

    ScrollTrigger.create({
      trigger: '#RS-section-intro',
      start: 'top center',
      end: 'bottom center',
      toggleClass: {targets: "#RS-inner-background-logo", className: "RS-inner-background-logo--active"},
      scrub: 1
    })

    ScrollTrigger.create({
      trigger: 'main',
      start: 'top top',
      end: 'bottom top',
      animation: gsap.fromTo('#RS-background__logo', {rotationZ: 0 }, {rotationZ: -360}),
      scrub: 1
    })

    ScrollTrigger.create({
      trigger: '#RS-section-way',
      start: 'top center',
      end: 'center top',
      toggleClass: {targets: "#RS-background__orbit-first", className: "RS-background__orbit--active"},
      scrub: 1
    })

    ScrollTrigger.create({
      trigger: '#RS-section-stats',
      start: 'top center',
      end: 'center top',
      toggleClass: {targets: "#RS-background__orbit-second", className: "RS-background__orbit--active"},
      scrub: 1
    })

    ScrollTrigger.create({
      trigger: '#RS-section-careers',
      start: 'top center',
      end: 'center top',
      toggleClass: {targets: "#RS-background__orbit-third", className: "RS-background__orbit--active"},
      scrub: 1
    })

    ScrollTrigger.create({
      trigger: '#RS-section-portfolio',
      start: 'top center',
      end: '70% top',
      toggleClass: {targets: "#RS-background__orbit-fourth", className: "RS-background__orbit--active"},
      scrub: 1
    })

    ScrollTrigger.create({
      trigger: '#RS-section-blog',
      start: 'top 75%',
      end: 'top top',
      toggleClass: {targets: "#RS-background__orbit-fifth", className: "RS-background__orbit--active"},
      scrub: 1
    })

    ScrollTrigger.create({
      trigger: '#RS-section-newsletter',
      start: 'top 75%',
      end: 'top top',
      toggleClass: {targets: "#RS-background__orbit-sixth", className: "RS-background__orbit--active"},
      scrub: 1
    })

    ScrollTrigger.create({
      trigger: '#RS-section-contact',
      start: 'top 75%',
      end: 'bottom top',
      toggleClass: {targets: "#RS-background__orbit-seventh", className: "RS-background__orbit--active"},
      scrub: 1
    })

  }

  if(pageName === 'Careers'){

    const cards = gsap.utils.toArray(".RS-recruitment-step");
    const spacer = 32;

    cards.forEach((card, index) => {
      ScrollTrigger.create({
        trigger: card,
        start: `top-=${index * spacer} top`,
        endTrigger: '.RS-recruitment-steps',
        end: `bottom top+=${400 + (1 * spacer)}`,
        pin: true,
        pinSpacing: false,
        markers: false,
        id: 'pin',
        invalidateOnRefresh: true,
      });
    });

    ScrollTrigger.create({
      trigger: '#RS-section-careers-intro',
      start: 'top top',
      end: 'bottom top',
      animation: gsap.fromTo('.RS-intro__photos', {yPercent: 0 }, {yPercent: 10}),
      scrub: 1
    })

    

    const teamMemberVideos = gsap.utils.toArray(".RS-team-member__video");

    teamMemberVideos.forEach((video, index) => {

      ScrollTrigger.create({
        trigger: video,
        start: 'top bottom+=1000',
        onEnter: () => {
          video.preload = 'metadata';
        },
        once: true
      });
      
      ScrollTrigger.create({
        trigger: video,
        start: `top bottom`,
        end: `bottom top`,
        markers: false,
        onEnter: () => {
          video.play();
        },
        onLeave: () => {
          video.pause();
        },
        onEnterBack: () => {
          video.play();
        },
        onLeaveBack: () => {
          video.pause();
        },
      });
    });

  }


  if(pageName === 'Founder'){


    const cards = gsap.utils.toArray(".RS-recruitment-step");
    let spacer = 24;

    if (window.innerWidth < 480) {
      spacer = 16;
    }

    if (window.innerHeight > 430) {
      cards.forEach((card, index) => {
        ScrollTrigger.create({
          trigger: card,
          start: `top-=${index * spacer} top`,
          endTrigger: '.RS-recruitment-steps',
          end: `bottom top+=${400 + (1 * spacer)}`,
          pin: true,
          pinSpacing: false,
          markers: false,
          id: 'pin',
          invalidateOnRefresh: true,
        });
      });
    }

    function normalizePath(path) {
      return path.replace(/^\/|\/$/g, '').toLowerCase();
    }
    const currentPage = normalizePath(window.location.pathname);

    if(currentPage === 'founder/ai') {

    }


    ScrollTrigger.create({
      trigger: '#RS-section-founder-intro',
      start: 'top top',
      end: 'bottom center',
      animation: gsap.fromTo('.RS-intro-founder-background-inner', {scale: 1, opacity: 1 }, {scale: .96, opacity: 1}),
      scrub: 1
    })


    const teamMemberVideos = gsap.utils.toArray(".RS-team-member__video");

    teamMemberVideos.forEach((video, index) => {

      ScrollTrigger.create({
        trigger: video,
        start: 'top bottom+=1000',
        onEnter: () => {
          video.preload = 'metadata';
        },
        once: true
      });
      
      ScrollTrigger.create({
        trigger: video,
        start: `top bottom`,
        end: `bottom top`,
        markers: false,
        onEnter: () => {
          video.play();
        },
        onLeave: () => {
          video.pause();
        },
        onEnterBack: () => {
          video.play();
        },
        onLeaveBack: () => {
          video.pause();
        },
      });
    });


  }

  if(pageName === 'Connect'){


    ScrollTrigger.create({
      trigger: '#RS-section-connect-intro',
      start: 'top top',
      end: 'bottom top',
      animation: gsap.fromTo('.RS-intro__container--connect', {yPercent: 0, rotateY: 0, opacity: 1 }, {yPercent: 2.5, rotateY: 0, opacity: 1}),
      scrub: 1
    })

    // ScrollTrigger.create({
    //   trigger: '#RS-section-connect-intro',
    //   start: 'top top',
    //   end: 'bottom top',
    //   animation: gsap.fromTo('.RS-intro__photo-connect', {opacity: 1}, {opacity: 0.5}),
    //   scrub: 1
    // })

  }



};
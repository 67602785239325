import Cookies from "js-cookie";

const cookieBarId = "cookie-bar";
const acceptButtonSelector = "[data-cookies='accept']";
const rejectButtonSelector = "[data-cookies='reject']";
const visibleClassName = "RS-cookie-bar--visible";
const cookieName = "cookies-allowed";
const cookieValue = "true";
const localStorageName = "cookies-rejected";
const localStorageValue = "true";

export const initCookiePolicy = () => {
  const bar = document.getElementById(cookieBarId);
  if (!bar) return;

  const savedCookie = Cookies.get(cookieName);
  const savedLocalStorageItem = window.localStorage.getItem(localStorageName);

  if (!savedCookie && !savedLocalStorageItem) {
    showBar(bar);
    return;
  }

  bar.remove();
};

const showBar = (bar) => {
  setTimeout(() => {
    bar.classList.add(visibleClassName);
    handleBarActions(bar);
  }, "1500")
};

const handleBarActions = (bar) => {
  const acceptButton = document.querySelector(acceptButtonSelector);
  const rejectButton = document.querySelector(rejectButtonSelector);

  acceptButton.addEventListener(
    "click",
    (event) => {
      event.preventDefault();
      saveAcceptInCookies();
      hideBar(bar);
    },
    true
  );
  rejectButton.addEventListener(
    "click",
    (event) => {
      event.preventDefault();
      saveRejectionInLocalStorage();
      hideBar(bar);
    },
    true
  );
};

const hideBar = (bar) => {
  bar.classList.remove(visibleClassName);
  bar.addEventListener("transitionend", () => bar.remove());
};

const saveRejectionInLocalStorage = () => {
  window.localStorage.setItem(localStorageName, localStorageValue);
};

const saveAcceptInCookies = () => {
  Cookies.set(cookieName, cookieValue, { expires: 365 });
};
